<template>
	<div class="container mt-5 mb-5 h-100">
		<div class="row h-100">
			<div class="glance col-3">
				<h1 class="mb-0">{{ title }}</h1>
				<p class="mt-2 mb-0">{{ excerpt }}</p>
			</div>
			<div class="col-9">
				<div class="row flex-row-reverse h-100">
					<slot/>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "Header",
	props: ["title", "excerpt"]
}
</script>

<style lang="scss" scoped>
.glance {
	h1 {
		font-size: 25px !important;
		font-weight: 600 !important;
		color: $text-dark;
	}

	p {
		font-size: 15px;
		margin-top: 15px;
		font-weight: 500;
		color: $text-dark-secondary;
	}
}
</style>
