<template>
	<div>
		<Navbar />
		<Header title="Compose" excerpt="Send a message to an individual recepient just one time." />

		<!-- CONTENT -->
		<b-container>
			<b-row>

				<!-- FORM -->
				<b-col md="8">
					<b-form>
						<div class="d-flex">
							
							<div class="form-group mr-3" style="flex: 1">
								<label>Sender ID</label>
								<b-form-select
									id="inline-form-custom-select-pref"
									class="w-100"
									:options="masks"
									v-model="form.fields.sender">
								</b-form-select>
							</div>

							<div class="form-group ml-3" style="flex: 1">
								<label for="">Recepient Number</label>
								<b-form-input v-model="form.fields.recipient" placeholder="+94777123456"></b-form-input>
							</div>

						</div>

						<div class="form-group">
							<label for="message">Message</label>
							<b-textarea v-model="form.fields.message"></b-textarea>
						</div>						

					</b-form>
				</b-col>

				<!-- ESTIMATION CALCULATE -->
				<b-col md="4">
					<b-card no-body class="calc-card mb-3">
						<b-card-body>

							<div>
								<div class="measurement">Number of pages</div>
								<div class="value">{{ get_page_count }} /8</div>
							</div>

							<br>

							<div>
								<div class="measurement">Cost per page</div>
								<div class="value">{{ this.$store.state.user.package.cost.sms }}</div>
							</div>

							<br>

							<div>
								<div class="measurement">Estimated total cost</div>
								<div class="value">{{ get_total_cost }} Lkr</div>
							</div>


						</b-card-body>
					</b-card>

					<b-button @click="On_Submit" variant="primary" :disabled="button.submitting" block>Send</b-button>
				</b-col>

			</b-row>
		</b-container>

	</div>
</template>

<script>
import config from "../../config.json"
import Header from "@/components/Header";
import Navbar from "@/components/Navbar";

export default {
	name: "Compose",
	components: { Header, Navbar },

	data: () => ({
		form: {
			fields: {
				recipient: null,
				sender: null,
				message: null
			}
		},
		button: {
			submitting: false
		}
	}),

	mounted() {
		this.$store.dispatch("user/initialize")
		this.$store.dispatch("masks/initialize")
	},

	computed: {

		masks() {
			let options = []
			this.$store.state.masks.masks.forEach(mask => {
				options.push({
					text: mask.name,
					value: mask.name
				})
			});
			return options
		},

		get_character_count: function() { return new Blob([this.from.fields.message]).size },
		get_page_count: function() { return Math.ceil(new Blob([this.form.fields.message]).size / 160) },
		get_total_cost: function() { return Math.ceil(new Blob([this.form.fields.message]).size / 160) * this.$store.state.user.package.cost.sms }

	},

	methods: {

		On_Submit: function() {

			this.button.submitting = true

			fetch(config.api.protocol + "://" + config.api.fqdn + "/v2.0/sms", {
				method: "POST",
				headers: { "Authorization": "Bearer " + localStorage.getItem("token") },
				body: JSON.stringify({
					to: [this.form.fields.recipient],
					from: this.form.fields.sender,
					message: this.form.fields.message
				})
			}).then(Response => Response.json()).then(Response => {
				console.log(Response)
			})

			/**
			 * Get button to active state and reset all the data fields
			 * back to the defaults to make the form ready for sending
			 * again.
			 */
			this.button.submitting = false
			this.form.fields.recipient = null
			this.form.fields.message = null

		}

	}

}
</script>

<style lang="scss" scope>
.calc-card {
	border-radius: 5px;
	border: solid 2px $base-primary;
	background: $base-secondary;

	.measurement {
		color: $text-secondary;
		font-size: 14px;
		font-weight: 600;
		margin-bottom: 5px;
	}

	.value {
		color: $text-primary;
		font-size: 20px;
		font-weight: 500;
	}
}

textarea {
	min-height: 200px;
}
</style>
